import { useDispatch, useSelector } from 'react-redux';
import { tree } from '../features/auth/authSlice';
import {useEffect} from "react";
const Tree = () => {
    const dispatch = useDispatch();
    const { status, error } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(tree());
    }, []);
    return (
        <div>
            <h1>Tree</h1>
        </div>
    );
}

export default Tree;